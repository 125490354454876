import React from 'react';


const ChooseGameImg = ({ width='631', height='135', viewBox='0 0 631 135' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#choose-game-filter0_dd)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M410.375 52.761a3.17 3.17 0 013.146 2.773l.025.397v12.683c0 .5-.119.99-.344 1.433l-1.234 2.01c-2.946 4.94-11.104 19.936-11.104 34.602a3.17 3.17 0 01-2.773 3.146l-.398.025h-19.022a3.17 3.17 0 01-3.098-3.859l.396-1.684c1.266-5.23 5.45-21.197 11.68-31.706l.483-.797h-12.633a3.172 3.172 0 01-3.145-2.772l-.024-.398V55.931a3.17 3.17 0 012.772-3.145l.397-.025h34.876zm-60.239 0a3.172 3.172 0 013.147 2.773l.024.397v12.683a3.187 3.187 0 01-.303 1.35l-.229.406c-.121.185-12.149 18.48-12.149 36.289a3.171 3.171 0 01-2.774 3.146l-.397.025h-19.023a3.169 3.169 0 01-3.097-3.859l.613-2.571c1.483-5.966 5.552-20.849 11.462-30.819l.483-.797h-12.632a3.17 3.17 0 01-3.145-2.772l-.025-.398V55.931c0-1.615 1.21-2.95 2.772-3.145l.398-.025h34.875zm-63.408 0a3.17 3.17 0 013.145 2.773l.025.397v12.683c0 .5-.119.99-.344 1.433l-1.453 2.38c-3.111 5.283-10.885 19.915-10.885 34.232a3.17 3.17 0 01-2.773 3.146l-.398.025h-19.022a3.17 3.17 0 01-3.098-3.859l.745-3.074c1.582-6.264 5.582-20.615 11.331-30.316l.483-.797h-12.631a3.172 3.172 0 01-3.147-2.772l-.024-.398V55.931a3.17 3.17 0 012.773-3.145l.398-.025h34.875zm120.477 6.341H378.67v6.341h15.853a3.17 3.17 0 012.241 5.412c-6.044 6.044-11.233 21.985-13.79 31.592l-.273 1.041h11.927c1.03-15.728 9.33-30.51 12.175-35.155l.402-.648v-8.583zm-60.239 0h-28.534v6.341h15.853a3.17 3.17 0 012.241 5.412c-6.045 6.044-11.234 21.985-13.792 31.592l-.273 1.041h11.928c1.057-16.141 9.773-31.287 12.388-35.5l.189-.303v-8.583zm-63.409 0h-28.535v6.341h15.853a3.17 3.17 0 012.241 5.412c-6.043 6.044-11.233 21.985-13.79 31.592l-.273 1.041h11.927c1.002-15.314 8.898-29.73 11.939-34.767l.638-1.036v-8.583zm0-23.875a3.172 3.172 0 01.397 6.316l-.397.025h-15.853a3.17 3.17 0 01-.397-6.316l.397-.025h15.853zm31.875 0a3.17 3.17 0 01.398 6.316l-.398.025h-.171a3.17 3.17 0 01-.398-6.316l.398-.025h.171zm91.773 0a3.17 3.17 0 01.397 6.316l-.397.025h-15.853a3.17 3.17 0 01-.397-6.316l.397-.025h15.853z"
              fill="#fff"/>
        <path
          d="M299.41 24.44A15.8 15.8 0 00286.728 18h-34.875l-.746.018c-8.405.399-15.097 7.333-15.107 15.835v85.795h6.341V33.853l.018-.602c.311-4.973 4.442-8.91 9.494-8.91h34.875l.601.019a9.512 9.512 0 018.909 9.493h6.341l.019-.602a9.511 9.511 0 019.493-8.91h38.045l.602.019a9.512 9.512 0 018.91 9.493h6.341l.018-.602c.311-4.973 4.442-8.91 9.492-8.91h34.875l.602.019a9.512 9.512 0 018.91 9.493v85.795h6.341V33.853l-.018-.746c-.398-8.405-7.333-15.097-15.834-15.107h-34.876l-.88.027a15.797 15.797 0 00-11.801 6.412A15.795 15.795 0 00350.136 18h-38.045l-.88.027a15.793 15.793 0 00-11.801 6.412z"
          fill="#fff"/>
        <path clipRule="evenodd"
              d="M410.375 52.761a3.17 3.17 0 013.146 2.773l.025.397v12.683c0 .5-.119.99-.344 1.433l-1.234 2.01c-2.946 4.94-11.104 19.936-11.104 34.602a3.17 3.17 0 01-2.773 3.146l-.398.025h-19.022a3.17 3.17 0 01-3.098-3.859l.396-1.684c1.266-5.23 5.45-21.197 11.68-31.706l.483-.797h-12.633a3.172 3.172 0 01-3.145-2.772l-.024-.398V55.931a3.17 3.17 0 012.772-3.145l.397-.025h34.876zm-60.239 0a3.172 3.172 0 013.147 2.773l.024.397v12.683a3.187 3.187 0 01-.303 1.35l-.229.406c-.121.185-12.149 18.48-12.149 36.289a3.171 3.171 0 01-2.774 3.146l-.397.025h-19.023a3.169 3.169 0 01-3.097-3.859l.613-2.571c1.483-5.966 5.552-20.849 11.462-30.819l.483-.797h-12.632a3.17 3.17 0 01-3.145-2.772l-.025-.398V55.931c0-1.615 1.21-2.95 2.772-3.145l.398-.025h34.875zm-63.408 0a3.17 3.17 0 013.145 2.773l.025.397v12.683c0 .5-.119.99-.344 1.433l-1.453 2.38c-3.111 5.283-10.885 19.915-10.885 34.232a3.17 3.17 0 01-2.773 3.146l-.398.025h-19.022a3.17 3.17 0 01-3.098-3.859l.745-3.074c1.582-6.264 5.582-20.615 11.331-30.316l.483-.797h-12.631a3.172 3.172 0 01-3.147-2.772l-.024-.398V55.931a3.17 3.17 0 012.773-3.145l.398-.025h34.875zm120.477 6.341H378.67v6.341h15.853a3.17 3.17 0 012.241 5.412c-6.044 6.044-11.233 21.985-13.79 31.592l-.273 1.041h11.927c1.03-15.728 9.33-30.51 12.175-35.155l.402-.648v-8.583zm-60.239 0h-28.534v6.341h15.853a3.17 3.17 0 012.241 5.412c-6.045 6.044-11.234 21.985-13.792 31.592l-.273 1.041h11.928c1.057-16.141 9.773-31.287 12.388-35.5l.189-.303v-8.583zm-63.409 0h-28.535v6.341h15.853a3.17 3.17 0 012.241 5.412c-6.043 6.044-11.233 21.985-13.79 31.592l-.273 1.041h11.927c1.002-15.314 8.898-29.73 11.939-34.767l.638-1.036v-8.583zm0-23.875a3.172 3.172 0 01.397 6.316l-.397.025h-15.853a3.17 3.17 0 01-.397-6.316l.397-.025h15.853zm31.875 0a3.17 3.17 0 01.398 6.316l-.398.025h-.171a3.17 3.17 0 01-.398-6.316l.398-.025h.171zm91.773 0a3.17 3.17 0 01.397 6.316l-.397.025h-15.853a3.17 3.17 0 01-.397-6.316l.397-.025h15.853z"
              stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M299.41 24.44A15.8 15.8 0 00286.728 18h-34.875l-.746.018c-8.405.399-15.097 7.333-15.107 15.835v85.795h6.341V33.853l.018-.602c.311-4.973 4.442-8.91 9.494-8.91h34.875l.601.019a9.512 9.512 0 018.909 9.493h6.341l.019-.602a9.511 9.511 0 019.493-8.91h38.045l.602.019a9.512 9.512 0 018.91 9.493h6.341l.018-.602c.311-4.973 4.442-8.91 9.492-8.91h34.875l.602.019a9.512 9.512 0 018.91 9.493v85.795h6.341V33.853l-.018-.746c-.398-8.405-7.333-15.097-15.834-15.107h-34.876l-.88.027a15.797 15.797 0 00-11.801 6.412A15.795 15.795 0 00350.136 18h-38.045l-.88.027a15.793 15.793 0 00-11.801 6.412z"
          stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <g opacity=".5" filter="url(#choose-game-filter1_dd)" clipRule="evenodd">
        <path fillRule="evenodd"
              d="M495.5 15a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.479-2.017-4.5-4.5-4.5z"
              fill="#fff"/>
        <path
          d="M495.5 15a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.479-2.017-4.5-4.5-4.5z"
          stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <g opacity=".75" filter="url(#choose-game-filter2_dd)" clipRule="evenodd">
        <path fillRule="evenodd"
              d="M611.5 57a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
              fill="#fff"/>
        <path
          d="M611.5 57a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
          stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <g opacity=".15" filter="url(#choose-game-filter3_dd)" clipRule="evenodd">
        <path fillRule="evenodd"
              d="M19.5 40a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
              fill="#fff"/>
        <path d="M19.5 40a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
              stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <g opacity=".25" filter="url(#choose-game-filter4_dd)" clipRule="evenodd">
        <path fillRule="evenodd"
              d="M111.5 73a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
              fill="#fff"/>
        <path
          d="M111.5 73a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
          stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <filter id="choose-game-filter0_dd" x="221" y="3" width="220.227" height="131.648" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.5"/>
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="6"/>
          <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
        </filter>
        <filter id="choose-game-filter1_dd" x="476" y="0" width="39" height="39" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.5"/>
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="6"/>
          <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
        </filter>
        <filter id="choose-game-filter2_dd" x="592" y="42" width="39" height="39" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.5"/>
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="6"/>
          <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
        </filter>
        <filter id="choose-game-filter3_dd" x="0" y="25" width="39" height="39" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.5"/>
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="6"/>
          <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
        </filter>
        <filter id="choose-game-filter4_dd" x="92" y="58" width="39" height="39" filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6.5"/>
          <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="6"/>
          <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>
  )
};

export default ChooseGameImg;