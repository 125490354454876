import React, { memo, useRef } from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import { useHasBeenVisible } from "../utils/helpers"
import {
  breakpoints,
  color,
  ContentStyles,
  wrapper,
  ContentStylesExt,
} from "../utils/style"
import Layout from "../components/shared/layoutParallax"
import SEO from "../components/SEO/SEO"
import styled from "@emotion/styled"
import Steps from "../components/Content/Home/Steps"
import PlayForFree from "../components/Content/Home/PlayForFreeStatic"
import ChooseGameImg from "../components/Content/Home/ChooseGameImg"
import GetWindowWidth from "../components/shared/GetWindowWidth"
import MainSlider from "../components/Content/Home/MainSlider"

const ProductOffers = loadable(() =>
  import("../components/Content/Home/ProductOffersStatic")
)
const LiveOnlineCasinos = loadable(() =>
  import("../components/Content/Home/LiveOnlineCasinosStatic")
)
const PlayHolidayGames = loadable(() =>
  import("../components/Content/Home/PlayHolidayGamesStatic")
)
const Faq = loadable(() => import("../components/Content/Home/FaqStatic"))
const GameProviders = loadable(() =>
  import("../components/Content/Home/GameProvidersStatic")
)

const WrapperDesktop = styled("div")`
  ${wrapper}
`

const ContentWrapper = styled("div")`
  margin-bottom: 50px;
  ${ContentStyles}
  ${ContentStylesExt}
  text-align: center;
  background: rgba(22, 1, 1, 0.8);
  max-height: 280px;
  overflow-y: scroll;
  padding: 0 15px;
  h2,
  h3 {
    color: ${color.white};
    font-weight: bold;
    font-size: 40px;
    line-height: 42px;
  }
  .a-left {
    margin-bottom: 15px;
  }
  .show-more {
    .more-span {
      display: inline;
    }
    .less-span {
      display: none;
    }
  }
  .show-less {
    .more-span {
      display: none;
    }
    .less-span {
      display: inline;
    }
  }
  .home-text-content {
    font-size: 14px;
    line-height: 1.62;
    text-align: left;
  }
  .full-height {
    max-height: 50000px;
  }
  .home-image-flex {
    svg {
      width: 150px;
    }
    .col-left {
      flex: 1;
      p {
        margin-top: 0;
      }
    }
    .col-right {
      flex: 0;
      padding-left: 20px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .home-text-content {
      margin-bottom: 20px;
      max-height: 585px;
    }
    .full-height {
      max-height: 50000px;
    }
    .home-image-flex {
      .col-left,
      .col-right {
        flex: auto;
      }
      .col-right {
        text-align: center;
      }
    }
  }
`

const CardsWrapper = styled("div")`
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .h1 {
    margin-top: -25px;
    text-transform: uppercase;
  }
  @media (max-width: ${breakpoints.xs}) {
    .h1 {
      margin-top: -40px;
    }
    span {
      font-size: 22.8px;
      max-width: 280px;
      text-align: center;
      margin-top: -48px;
    }
  }
`

const HomePage = ({ data }) => {
  const windowWidth = GetWindowWidth()
  const isDesktop = windowWidth > 600
  const homePageNode = data.allWordpressPage.edges[0].node
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.OWNER
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : ""
  const slider = isDesktop
    ? homePageNode.acf.top_slider
    : homePageNode.acf.top_slider_mobile

  const socialImage = homePageNode.featured_media?.localFile?.publicURL

  const halfPage = useRef()
  const hasScrolled = useHasBeenVisible(halfPage)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        showSchema={true}
        socialImage={socialImage}
      />
      <div id="home-content-all" className="with-overflow-slider">
        <MainSlider slides={slider} isDesktop={isDesktop} />

        <WrapperDesktop>
          <Steps />

          <PlayForFree isDesktop={isDesktop} />

          {hasScrolled ? (
            <div className={"non-seo-content-loaded"}>
              <ProductOffers />

              <LiveOnlineCasinos />

              <PlayHolidayGames />
            </div>
          ) : (
            <div className={"non-seo-content"} ref={halfPage} />
          )}
        </WrapperDesktop>

        <CardsWrapper>
          <ChooseGameImg />
          <h2 className="h1 rich-design a-center">เลือกผู้ให้บริการคาสิโน</h2>
        </CardsWrapper>

        <WrapperDesktop>
          <GameProviders />

          <Faq isDesktop={isDesktop} />

          {homePageNode.content && (
            <ContentWrapper>
              <div dangerouslySetInnerHTML={{ __html: homePageNode.content }} />
            </ContentWrapper>
          )}
        </WrapperDesktop>
      </div>
    </Layout>
  )
}

export default memo(HomePage)

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home-page" } }) {
      edges {
        node {
          title
          content
          wordpress_id
          featured_media {
            alt_text
            source_url
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          acf {
            top_slider {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1224) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            top_slider_mobile {
              alt_text
              title
              path
              acf {
                slide_title
                slide_description
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 580) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
