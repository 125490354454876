import React from "react"
import Img from "gatsby-image"
import { Carousel } from "react-responsive-carousel"

import styled from "@emotion/styled"
import {
  breakpoints,
  color,
  wrapper1200,
  CarouselStyles,
} from "../../../utils/style"

const SliderWrap = styled("div")`
  ${wrapper1200}
  ${CarouselStyles}
  
  position: relative;

  .carousel-wrapper {
    aspect-ratio: 2.47/1;
    overflow: hidden;
  }

  .home-slider__slide {
    height: 100%;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    background: ${color.black};
    border-radius: 24px;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }

  @media (max-width: ${breakpoints.sm}) {
    .carousel-wrapper {
      aspect-ratio: 1.26/1;
    }
  }
`

const TextBlock = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1150px;
  max-width: 100%;
  max-height: 100%;
  padding: 25px;
  text-align: left;
  transform: translate3d(-50%, -50%, 0);
  overflow: hidden;

  .h1 {
    margin-bottom: 4px;
    font-size: 52px;
    font-weight: bold;
    color: ${color.orange};
    text-transform: uppercase;
  }
  .p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  @media (max-width: ${breakpoints.lg}) {
    .h1 {
      font-size: 2rem;
      margin: 0 0 1rem;
    }
    .p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 20px;

    .h1 {
      font-size: 1.4rem;
      margin-top: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .p {
      font-size: 0.8rem;
      height: 18px;
      overflow: hidden;
    }
    .button {
      padding: 12px 20px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    top: 48%;
    .h1 {
      margin: 0;
    }
    .p {
      margin-bottom: 8px;
    }
    .button {
      padding: 10px 20px;
      height: 36px;
    }
  }
`

const MainSlider = ({ slides, isDesktop }) => {
  if (!slides.length) return null

  const Items = slides.map((item, index) => {
    const link = item.acf ? item.acf.slide_url : ""
    const { childImageSharp = {} } = item.localFile || {}
    const line1 = item.acf ? item.acf.slide_title : ""
    const line2 = item.acf ? item.acf.slide_description : ""
    const ext = item.acf && item.acf.slide_url.indexOf("http") !== -1
    const anchor = item.acf && item.acf.slide_url.indexOf("#") !== -1

    return !link ? (
      <div className={"home-slider__slide"} key={"slide-" + index}>
        <Img
          fluid={childImageSharp.fluid}
          alt={item.alt_text ? item.alt_text : "slide"}
          fadeIn={false}
          // loading="eager"
        />
        {(line1 || line2) && (
          <TextBlock className="text-block">
            {line1 && <div className="h1">{line1}</div>}
            {line2 && <div className="p">{line2}</div>}
          </TextBlock>
        )}
      </div>
    ) : (
      <a
        className={"home-slider__slide"}
        key={"slide-" + index}
        href={ext || anchor ? link : `${process.env.URL}` + link}
      >
        <Img
          fluid={childImageSharp.fluid}
          alt={item.alt_text ? item.alt_text : "slide"}
          fadeIn={false}
          // loading="eager"
        />
        {(line1 || line2) && (
          <TextBlock className="text-block">
            {line1 && <div className="h1">{line1}</div>}
            {line2 && <div className="p">{line2}</div>}
            <span className="button gradient-button">คลิกเลย</span>
          </TextBlock>
        )}
      </a>
    )
  })

  return (
    <SliderWrap>
      <div className="carousel-wrapper">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          showArrows={false}
          showIndicators={isDesktop}
          interval={5000}
        >
          {Items}
        </Carousel>
      </div>
    </SliderWrap>
  )
}

export default MainSlider
