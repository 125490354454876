import React from "react";

const Step = ({
  width = "202",
  className = "",
  height = "120",
  viewBox = "0 0 202 120",
  id = "step-icon-a",
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id={id}
        x1="153.275%"
        x2="40.047%"
        y1="81.498%"
        y2="23.745%"
      >
        <stop offset="0%" stopColor="#ED012A" />
        <stop offset="100%" stopColor="#EE012B" stopOpacity="0" />
      </linearGradient>
    </defs>
    <path
      fill={"url(#" + id + ")"}
      fillRule="evenodd"
      d="M368 0l40.7 55.255a8 8 0 010 9.49L368 120H210V0h158z"
      opacity=".6"
      transform="translate(-210)"
    />
  </svg>
);

export default Step;
