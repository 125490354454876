import React from 'react';
import PropTypes from 'prop-types';
import PopupWindow, { invokePopup, closePopup } from '../../shared/PopupWindow';
import ResetLinkForm from './ResetLinkForm';


const resetLinkFormPopupOpen = (event, popupId, loginPopupId) => {
  event.preventDefault();
  closePopup(event, loginPopupId);
  invokePopup(event, popupId);
};

const ResetLinkFormPopup = (popupId) => {
  return (
    <div>
      <PopupWindow
        title="ลืมรหัสผ่าน?"
        popupId={popupId.popupId}
        extraClass="title-orange a-center full-width-popup"
      >
        <ResetLinkForm popupId={popupId.popupId} />
      </PopupWindow>
    </div>

  );
};
export default ResetLinkFormPopup;
export { resetLinkFormPopupOpen };

ResetLinkFormPopup.propTypes = {
  popupId: PropTypes.string
};
