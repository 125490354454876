import React, { useContext, useState, memo } from "react"
import styled from "@emotion/styled"
import { graphql, StaticQuery, navigate } from "gatsby"
import { breakpoints, color, sizing } from "../../../utils/style"
import Img from "gatsby-image"
import IcomoonIcon from "../../shared/IcomoonIcon"
import PopupIframe, { invokePopup } from "../../shared/PopupIframe"
import LoginFormPopup from "../../Auth/Form/LoginFormPopup"
import ResetLinkFormPopup from "../../Auth/Form/ResetLinkFormPopup"
import StoreContext from "../../shared/Context/StoreContext"
import Crown from "../../../icons/svg/Crown"

const PlayForFreeWrap = styled("div")`
  .title-block {
    position: relative;
    margin: 9rem 0 1rem;
  }
  .h1 {
    margin: 0 auto 40px;
    text-transform: uppercase;
    z-index: 1;
    position: relative;
  }
  @media (max-width: ${breakpoints.md}) {
    .title-block {
      margin: 3.5rem 0 1rem;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .h1 {
      margin-bottom: 0.7rem;
    }
  }
`

const PlayForFreeAll = styled("div")`
  clear: both;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const PlayForFreeVideo = styled("div")`
  flex-basis: 34%;
  background: ${color.darkRed};
  height: 98.5%;
  position: relative;
  border-radius: 10px;
  .play-button {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin: -45px 0 0 -55px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    span {
      display: inline-block;
      width: 1px;
      text-indent: -9999px;
    }
    &:hover,
    &:active {
      svg {
        path {
          fill: ${color.goldHover} !important;
        }
      }
    }
  }
  .terms-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 60px 0 0 -50px;
    width: 100px;
    z-index: 1;
    visibility: hidden;
  }
  img {
    border-radius: 15px;
  }
  &:hover,
  &:active {
    .terms-button,
    .play-button {
      visibility: visible;
    }
    img {
      filter: brightness(20%);
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .terms-button,
    .play-button {
      visibility: visible;
    }
    .play-button {
      top: 38%;
    }
    .terms-button {
      top: 40%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    flex-basis: 100%;
  }
`

const GameAds = styled("div")`
  flex-basis: 62%;
  text-align: right;
  @media (max-width: ${breakpoints.sm}) {
    margin: 12px 0 0 0;
    flex-basis: 100%;
  }
`

const GameAd = styled("div")`
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 3.2% 3.3%;
  position: relative;
  .game-title {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: ${color.white};
  }
  .coming-soon,
  .show-more {
    width: 120px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: none;
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
  }
  .title-text {
    width: fit-content;
    top: 35%;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: ${color.white};
  }
  .ribbon {
    position: absolute;
    top: 12%;
    right: -5px;
    z-index: 1;
    padding: 5px 10px;
    background: ${color.orange};
    color: ${color.midRed};
    font-weight: bold;
    border-radius: 4px;
  }
  &:hover,
  &:active {
    .ribbon {
      display: none;
    }
    .coming-soon,
    .show-more,
    .game-title {
      display: inline-block;
    }
  }
  a,
  .a {
    display: block;
    &:hover,
    &:active {
      img {
        filter: brightness(20%);
      }
    }
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: calc(50% - 12px);
    margin: 12px 0;
    .game-title {
      font-size: ${sizing.small};
      padding: 10px 0;
    }
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }
    .coming-soon {
      font-size: 0.8rem;
    }
  }
`

const PlayForFree = ({ isDesktop }) => {
  const storeValue = useContext(StoreContext)
  const username = storeValue.username

  const [selectedGameUrl, setSelectedGameUrl] = useState("")
  const setGameUrlState = url => setSelectedGameUrl(url)

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressPage(filter: { slug: { eq: "home-page" } }) {
            edges {
              node {
                acf {
                  play_for_free_title
                  play_for_free_video_file {
                    source_url
                  }
                  play_for_free_video_cover {
                    alt_text
                    title
                    path
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 400) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  play_for_free {
                    alt_text
                    title
                    path
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 280) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  play_for_free_mobile {
                    alt_text
                    title
                    path
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 210) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const homePageNode = data.allWordpressPage.edges[0].node
        const playForFree = homePageNode.acf.play_for_free
        const playForFreeMobile = homePageNode.acf.play_for_free_mobile

        const iframe = homePageNode?.acf?.play_for_free_video_file?.source_url
        const title = homePageNode.acf.play_for_free_title
        const video_cover = homePageNode.acf.play_for_free_video_cover
        const play_free = isDesktop ? playForFree : playForFreeMobile

        if (!play_free || !play_free.length) {
          return null
        }

        const gameClick = (e, slide_description, slide_url) => {
          if (!username) {
            e.preventDefault()
            invokePopup(e, "login-popup-window")
          } else {
            navigate(slide_url)
          }

          setGameUrlState(slide_url)
          if (slide_description) {
            return false
          }
        }

        const videoClick = (e, iframeSrc) => {
          const homeVideoIframe = document.getElementById(
            "free-spin-home-iframe"
          )
          homeVideoIframe.src = iframeSrc
          invokePopup(e, "free-spin-home-popup")
        }

        return (
          <PlayForFreeWrap
            id="home-play-for-free"
            className="home-play-for-free"
          >
            <div className="title-block">
              <h1 className="h1 rich-design a-center">
                <span>{title}</span>
              </h1>
              <Crown />
            </div>
            <PlayForFreeAll>
              <PlayForFreeVideo>
                {iframe && (
                  <button
                    className="play-button"
                    onClick={e => videoClick(e, iframe)}
                  >
                    <IcomoonIcon
                      icon="play-button"
                      color={color.white}
                      size={90}
                    />
                    <span>Play</span>
                  </button>
                )}
                <a
                  href={process.env.FREESPIN_TERMS_URL_RELATIVE}
                  className="button gold-button terms-button"
                >
                  กติกา
                </a>
                {video_cover &&
                  video_cover.alt_text &&
                  video_cover.localFile &&
                  video_cover.localFile.childImageSharp && (
                    <Img
                      fluid={video_cover.localFile.childImageSharp.fluid}
                      alt={video_cover.alt_text}
                      fadeIn={false}
                      loading="eager"
                    />
                  )}
              </PlayForFreeVideo>
              <GameAds>
                {play_free.map(item => {
                  const localFile = item.localFile || {}
                  return (
                    <GameAd key={item.path}>
                      {item.acf.slide_title && (
                        <span className="ribbon">{item.acf.slide_title}</span>
                      )}
                      {item.acf.slide_description && (
                        <span className="coming-soon">
                          {item.acf.slide_description}
                        </span>
                      )}
                      {item.acf.slide_url ? (
                        <div
                          className="a"
                          role="button"
                          tabIndex={0}
                          onClick={e => {
                            gameClick(
                              e,
                              // item.title,
                              // item.alt_text,
                              item.acf.slide_description,
                              item.acf.slide_url
                            )
                          }}
                          onKeyDown={e => {
                            gameClick(
                              e,
                              item.acf.slide_description,
                              item.acf.slide_url
                            )
                          }}
                          // href={item.acf.slide_url}
                          // title={item.alt_text}
                          title={
                            "ขออภัยในความไม่สะดวก ขณะนี้เกมฟรีสปินปิดปรับปรุงระบบ จะกลับมาเปิดให้บริการเร็วๆนี้ ขอบคุณท่านลูกค้าที่สนใจร่วมสนุกกับเทพเอเชียนะคะ"
                          }
                        >
                          {!item.acf.slide_description && (
                            <span className="button gold-button show-more">
                              เล่นเลย
                            </span>
                          )}
                          {/*{!item.acf.slide_description &&*/}
                          {/*item.alt_text &&*/}
                          {/*<span className="show-more title-text">*/}
                          {/*{item.alt_text}*/}
                          {/*</span>*/}
                          {/*}*/}
                          {localFile.childImageSharp &&
                            localFile.childImageSharp &&
                            item.alt_text && (
                              <Img
                                fluid={localFile.childImageSharp.fluid}
                                alt={item.alt_text}
                                fadeIn={false}
                                loading="eager"
                              />
                            )}
                        </div>
                      ) : (
                        <div className="a">
                          {localFile.childImageSharp &&
                            localFile.childImageSharp &&
                            item.alt_text && (
                              <Img
                                fluid={localFile.childImageSharp.fluid}
                                alt={item.alt_text}
                                fadeIn={false}
                                loading="eager"
                              />
                            )}
                        </div>
                      )}
                    </GameAd>
                  )
                })}
              </GameAds>
              {iframe && (
                <PopupIframe
                  title="Free Spin"
                  popupId="free-spin-home-popup"
                  extraClass="wide"
                >
                  <div className="iframe-container">
                    <iframe
                      id="free-spin-home-iframe"
                      title="Free Spin"
                      src="about:blank"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                      allowFullScreen="allowFullScreen"
                      frameBorder={0}
                    />
                  </div>
                </PopupIframe>
              )}
              {!username && (
                <div>
                  <LoginFormPopup
                    popupId="login-popup-window"
                    redirectUrl={selectedGameUrl}
                  />
                  <ResetLinkFormPopup popupId="reset-link-popup" />
                </div>
              )}
            </PlayForFreeAll>
          </PlayForFreeWrap>
        )
      }}
    />
  )
}

export default memo(PlayForFree)
