import React from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../utils/style';

const SwgWrap = styled('div')`
  position: absolute;
  width: 631px;
  left: 0;
  right: 0;
  margin: 1.5rem auto 0;
  top: -130px;
  z-index: 0;
  @media (max-width: ${breakpoints.md}) {
    width: 318px;
    top: -70px;
    svg {
      width: 318px;
      height: auto;
    }
  }
`;

const Crown = ({width = '631', height = '139', viewBox = '0 0 631 139'}) => {
  return (
    <SwgWrap>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dd)">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M330.993 21.666l40.653 58.862 60.85-41.948-13.203 84.782h-6.737l10.534-70.194-53.566 35.635-39.354-56.717-40.332 56.717-52.505-35.618 10.539 70.177h-6.737l-13.203-84.793 60.795 41.959 40.62-58.862a1 1 0 011.646 0z"
                fill="#fff"/>
          <path
            d="M371.646 80.528L370 81.664a2 2 0 002.781.51l-1.135-1.646zm-40.653-58.862l-1.646 1.136 1.646-1.136zM432.496 38.58l1.976.307a1.999 1.999 0 00-3.111-1.954l1.135 1.647zm-13.203 84.781v2c.986 0 1.825-.718 1.976-1.692l-1.976-.308zm-6.737 0l-1.978-.297a2 2 0 001.978 2.297v-2zm10.534-70.194l1.978.297a2.002 2.002 0 00-3.086-1.962l1.108 1.665zm-53.566 35.635l-1.644 1.14a2 2 0 002.751.525l-1.107-1.665zM330.17 32.086l1.643-1.14a2 2 0 00-3.273-.019l1.63 1.16zm-40.332 56.717l-1.123 1.655a2 2 0 002.753-.496l-1.63-1.16zm-52.505-35.618l1.123-1.655a2.001 2.001 0 00-3.101 1.952l1.978-.297zm10.539 70.177v2a2 2 0 001.978-2.297l-1.978.297zm-6.737 0l-1.976.308c.151.974.99 1.692 1.976 1.692v-2zm-13.203-84.793l1.136-1.646a2 2 0 00-3.112 1.954l1.976-.308zm60.795 41.959l-1.136 1.646a1.998 1.998 0 002.782-.51l-1.646-1.136zm40.62-58.862l1.646 1.136-1.646-1.136zm43.944 57.725l-40.653-58.862-3.291 2.273L370 81.664l3.291-2.273zm58.07-42.457l-60.85 41.947 2.27 3.293 60.85-41.947-2.27-3.293zm-10.092 86.736l13.203-84.782-3.952-.615-13.203 84.781 3.952.616zm-8.713 1.692h6.737v-4h-6.737v4zm1.978-1.703l10.534-70.194-3.956-.594-10.534 70.194 3.956.594zm7.448-72.156l-53.566 35.635 2.215 3.33 53.566-35.635-2.215-3.33zm-50.815 36.16l-39.354-56.717-3.287 2.28 39.354 56.717 3.287-2.28zM328.54 30.926l-40.332 56.717 3.26 2.318 40.331-56.717-3.259-2.318zm-37.579 56.22L238.456 51.53l-2.246 3.31 52.505 35.618 2.246-3.31zm-55.606-33.665l10.539 70.177 3.956-.594-10.539-70.177-3.956.594zm5.78 71.88h6.737v-4h-6.737v4zm-15.179-86.485l13.203 84.793 3.952-.616-13.203-84.792-3.952.615zm63.907 40.005l-60.795-41.959-2.272 3.292 60.795 41.959 2.272-3.292zm37.838-58.352l-40.62 58.862 3.292 2.272 40.62-58.862-3.292-2.272zm4.937 0c-1.193-1.728-3.745-1.728-4.937 0l3.292 2.272a1 1 0 01-1.646 0l3.291-2.273z"
            fill="#FF5E5E"/>
        </g>
        <g filter="url(#filter1_dd)" clipRule="evenodd">
          <path fillRule="evenodd"
                d="M329.699 61A5.707 5.707 0 00324 66.699a5.705 5.705 0 005.699 5.699 5.705 5.705 0 005.699-5.699A5.707 5.707 0 00329.699 61z"
                fill="#fff"/>
          <path
            d="M329.699 61A5.707 5.707 0 00324 66.699a5.705 5.705 0 005.699 5.699 5.705 5.705 0 005.699-5.699A5.707 5.707 0 00329.699 61z"
            stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g opacity=".5" filter="url(#filter2_dd)" clipRule="evenodd">
          <path fillRule="evenodd"
                d="M495.5 15a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.479-2.017-4.5-4.5-4.5z"
                fill="#fff"/>
          <path
            d="M495.5 15a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.479-2.017-4.5-4.5-4.5z"
            stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g opacity=".75" filter="url(#filter3_dd)" clipRule="evenodd">
          <path fillRule="evenodd"
                d="M611.5 57a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
                fill="#fff"/>
          <path
            d="M611.5 57a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
            stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g opacity=".15" filter="url(#filter4_dd)" clipRule="evenodd">
          <path fillRule="evenodd"
                d="M19.5 40a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
                fill="#fff"/>
          <path
            d="M19.5 40a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
            stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g opacity=".25" filter="url(#filter5_dd)" clipRule="evenodd">
          <path fillRule="evenodd"
                d="M111.5 73a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
                fill="#fff"/>
          <path
            d="M111.5 73a4.506 4.506 0 00-4.5 4.5c0 2.48 2.017 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.478-2.017-4.5-4.5-4.5z"
            stroke="#FF5E5E" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
          <filter id="filter0_dd" x="212.932" y="6.234" width="234.564" height="132.128" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="6.5"/>
            <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="6"/>
            <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
          </filter>
          <filter id="filter1_dd" x="309" y="46" width="41.398" height="41.398" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="6.5"/>
            <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="6"/>
            <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
          </filter>
          <filter id="filter2_dd" x="476" y="0" width="39" height="39" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="6.5"/>
            <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="6"/>
            <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
          </filter>
          <filter id="filter3_dd" x="592" y="42" width="39" height="39" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="6.5"/>
            <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="6"/>
            <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
          </filter>
          <filter id="filter4_dd" x="0" y="25" width="39" height="39" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="6.5"/>
            <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="6"/>
            <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
          </filter>
          <filter id="filter5_dd" x="92" y="58" width="39" height="39" filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="6.5"/>
            <feColorMatrix values="0 0 0 0 0.839216 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="6"/>
            <feColorMatrix values="0 0 0 0 0.90124 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
            <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"/>
            <feBlend in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
          </filter>
        </defs>
      </svg>
    </SwgWrap>
  )
};

export default Crown;